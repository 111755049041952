<!-- Error demo pages -->
<template>
  <transition name="fade-in-up">
    <router-view></router-view>
  </transition>
</template>

<script>
import {MUT_SET_FARM_ID} from '@/core/services/variable';

export default {
  components: {},
  beforeMount() {
    this.$store.commit(MUT_SET_FARM_ID, this.$route.params.farmId)
  }
};
</script>
